import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Fab, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { grey } from '@material-ui/core/colors';
import Pillar1 from './images/GrowingIvy1.png';
import Pillar2 from './images/GrowingIvy2.png';
import Pegasus1 from './images/Pegasus1.png';
import Pegasus2 from './images/Pegasus2.png';
import Pegasus3 from './images/Pegasus3.png';
import Container from '@material-ui/core/Container';



const useStyles = makeStyles((theme) => ({
    mainFeaturedPost: {
        position: 'relative',
        color: grey['900'],
        backgroundColor: 'rgba(255,255,255,1.0)',
        // background: 'linear-gradient(to bottom, rgba(90,66,100,0.35) 0%, rgba(25,10,180,.35) 50%, rgba(255,237,188,.35) 100%)',
        // background: 'linear-gradient(to bottom, rgba(207,66,100,0.35) 0%, rgba(25,10,180,.35) 50%, rgba(255,237,188,.35) 100%)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        paddingTop: theme.spacing(36),
        paddingBottom: theme.spacing(36),
        padding: theme.spacing(3.5),
        // paddingTop: theme.spacing(9),
        // paddingBottom: theme.spacing(16),
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            listStyle: 'none',
        },
        // padding: theme.spacing(3.5),
    },
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        // backgroundColor: 'rgba(255,255,255,0.97)',
        // width: '80%',
        // filter: 'blur(8em)',
    },
    mainFeaturedPostContent: {
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            listStyle: 'none',
        },
    },
    mainFeaturedPostContentTitle: {
        position: 'relative',
        lineHeight: theme.spacing(.18),
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
    },
    fab: {
        backgroundColor: 'white',
        color: 'grey',
        marginTop: theme.spacing(3),
    },
    fabWrapper: {
        marginTop: theme.spacing(2),
        paddingTop: theme.spacing(3),
        display: 'flex',
        // justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
    },
    mainWrapper: {
        margin: theme.spacing(3.5),
        direction: 'column',
    },
    link: {
        color: 'white',
    }
}));



export default function TopCTA(props) {
    const classes = useStyles();
    const { post } = props;


    return (
        <>
            <div className={`${classes.mainFeaturedPost} `}
                style={{
                    backgroundImage: `url(${post.image5})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                    backgroundAttachment: "fixed",
                }}>
                <div className={classes.overlay} />
                <Grid container className={classes.mainFeaturedPostContent}>
                    {/* <div className={classes.mainFeaturedPostContent}>
                        <div className={classes.mainFeaturedPostContentTitle}>
                            <div className='flex margin'>

                                <img src={Pillar1} className="scale-down-width ">
                                </img>

                                <img src={Pillar2} className="scale-down-width ">
                                </img>
                            </div>
                        </div>
                    </div> */}
                    <Grid item sm={12}>
                        <Typography component="h3" variant="h5" color="inherit" className={`${classes.mainFeaturedPostContentTitle} `} gutterBottom>
                            <div className="border">
                                A future where sophisticated
           systems  <p></p> are
          accessible
           across generations
           </div>
                        </Typography>

                        <Typography className={classes.mainFeaturedPostContentTitle}>
                            This is the future I build for.
                                        </Typography>
                        {/* <a href="#about" className={`ignoreUnderline ${classes.mainFeaturedPostContentTitle}`}> */}
                            {/* <div className="space-fab margin-top"> */}
                            {/* <Fab variant="extended" size="large" className={`${classes.fab} ${classes.mainFeaturedPostContentTitle}`}>
                                <span className="space-alt"> About</span>
                                <IconButton disableRipple disabled> <ExpandMoreIcon /></IconButton>

                                {/* <img className="flex rounded resize-img" src="https://scontent.fewr1-1.fna.fbcdn.net/v/t1.0-9/92823856_10157550283822773_1692239302425051136_o.jpg?_nc_cat=108&_nc_sid=85a577&_nc_ohc=uBa9K6okE0IAX9fTEpr&_nc_ht=scontent.fewr1-1.fna&oh=74b587fe3a8df85927d6c6383d3b38a8&oe=5ECE4F01"></img> */}

                            {/* </Fab> */}
                            {/* </div> */}
                        {/* </a> */}
                        <div className={classes.mainFeaturedPostContent}>
                            <div className={classes.fabWrapper}>

                            </div>
                        </div>

                    </Grid>






                </Grid>
            </div>

            {/* </Paper> */}


        </>
    );
}


TopCTA.propTypes = {
    post: PropTypes.object,
};