import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Fab, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import Collapse from '@material-ui/core/Collapse';
import Toolbar from '@material-ui/core/Toolbar';
// import flower from './images/WhiteRose.png'
// import flower from './images/RanunculusAsiaticusFlower.png'
import Pegasus1 from './images/Pegasus1.png';
import Pegasus2 from './images/Pegasus2.png';
import Pegasus3 from './images/Pegasus3.png';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(2.5),
    paddingBottom: theme.spacing(1),
  },
  mainFeaturedPost: {
    position: 'relative',
    // color: grey['900'],
    background: 'linear-gradient(to bottom, rgba(207,66,100,0.35) 0%, rgba(25,10,180,.35) 50%, rgba(255,237,188,.35) 100%)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    paddingTop: theme.spacing(36),
    paddingBottom: theme.spacing(36),
    padding: theme.spacing(3.5),
    // paddingTop: theme.spacing(9),
    // paddingBottom: theme.spacing(16),
    [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
    },
    // padding: theme.spacing(3.5),
},
overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(255,255,255,0.97)',
    // width: '80%',
    filter: 'blur(8em)',
},
mainFeaturedPostContent: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
    },
},
  // mainFeaturedPost: {
  //   position: 'relative',
  //   // backgroundColor: 'rgba(0,0,0,1.9)',
  //   // background: 'linear-gradient(to bottom, rgba(207,66,100,0.35) 0%, rgba(25,10,180,.35) 50%, rgba(255,237,188,.35) 100%)',
  //   // color: theme.palette.common.white,
  //   backgroundSize: 'cover',
  //   backgroundRepeat: 'no-repeat',
  //   backgroundPosition: 'center',
  //   display: 'flex',
  //   justifyContent: 'center',
  //   paddingTop: theme.spacing(24),
  //   paddingBottom: theme.spacing(24),
  // },
  textColor: {
    // color: 'white',
  },
  // overlay: {
  //   // position: 'absolute',
  //   top: 0,
  //   bottom: 0,
  //   right: 0,
  //   left: 0,
  //   // backgroundColor: 'rgba(0,0,0,0.8)',
  //   // backgroundColor: 'rgba(255,255,255,0.9)',
  //   // width: '80%',
  //   // filter: 'blur(8em)',
  // },
  // mainFeaturedPostContent: {
  //   display: 'flex',
  //   justifyContent: 'center',
  //   flexWrap: 'wrap',
  //   listStyle: 'none',
  //   backgroundSize: 'cover',
  //   backgroundRepeat: 'no-repeat',
  //   backgroundPosition: 'center',
  // },
  mainFeaturedPostContentTitle: {
    position: 'relative',
    lineHeight: theme.spacing(.16),
    margin: theme.spacing(3),
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },

  fab: {
    backgroundColor: 'white',
    color: 'grey',
  },
  fabSpace: {
    margin: theme.spacing(1),
  },

  fabWrapper: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
  },
  mainWrapper: {
    margin: theme.spacing(3.5),
    direction: 'column',
  },
  link: {
    // color: 'white',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    marginRight: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  sidebarAboutBox: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(3.5),
  },
  sidebarSection: {
    marginTop: theme.spacing(3),
  },
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {

    overflowX: 'auto',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3.5),
    margin: theme.spacing(3.5),
  },
  toolbarLink: {
    flexShrink: 0,
    // color: 'white',
    margin: theme.spacing(1.5),
  },
  button: {
    color: 'white',
    background: 'black',
  },
  space: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    marginTop: theme.spacing(3.5),
    marginRight: theme.spacing(1.5),
  },
  center: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(3.5),
  },
  text: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    margin: theme.spacing(1.5),
  },
  textWithSpace: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
}));



export default function TopCTAAbout1(props) {
  const classes = useStyles();
  const { post, social } = props;
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = (event) => {
    event.preventDefault()
    setExpanded(!expanded);
  };

  // const handleContentClick = (event) => {
  //   event.preventDefault()
  // }
  return (
    <div id="about">
      <div className={`${classes.mainFeaturedPost} filtered`}
        style={{
          // backgroundImage: `url(${post.image3})`,
          // backgroundRepeat: "no-repeat",
          // backgroundPosition: "center center",
          // backgroundSize: "cover",
          // backgroundAttachment: "fixed",
          // perspective: "10px",
          // transform: "translateZ(-0.5px)",
          // transform: "scale(1.025)",
          // overflowX: "hidden",
        }}>
        <div className={classes.overlay}
        />
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.mainFeaturedPostContent}>
              <Grid item xs={10} sm={8} md={8} lg={6}>
                <Grid container direction="column" spacing={1} alignItems="center">
                  {/* <img className='resize' src={profilePic}></img> */}
                  <div className={classes.mainFeaturedPostContent}>
                <div className={classes.mainFeaturedPostContentTitle}>
              <div className='flex margin'>
                  {/* <img src={Pegasus1} className="scale-down-width "></img> */}
                  </div>
                  </div>
                  </div>
                  <Toolbar
                    variant="dense"
                    className={classes.toolbarLink}>
                    {social.map((network) => (
                      <Link display="block"
                        variant="body1"
                        href={network.url}
                        key={network}
                        className={classes.toolbarLink}>
                        <Grid container direction="row" spacing={1} alignItems="center">
                          <Grid item>
                            <network.icon />
                          </Grid>
                          <Grid item>{network.name}</Grid>
                        </Grid>
                      </Link>
                    ))}
                  </Toolbar>
                </Grid>
                {/* <Typography component="h6" color="inherit" className={classes.mainFeaturedPostContentTitle}>
                    About
                </Typography> */}
               
                <Typography
                  component="h6" color="inherit" className={classes.mainFeaturedPostContentTitle} gutterBottom paragraph>
                       As a Sr. Product/UX Designer with a background in mobile app annd web development, I'm a strategic architect of digital technology, products, and experiences. 
            
                </Typography>


              
                <Grid item className={classes.root}>
                  <span className={classes.space}>
                    <div className="y-center"
                    >
                      <div>
                        <b>Read More</b>
                      </div>
                      <div >
                        2 minute read
                            </div>
                    </div>
                  </span>
                  <Fab
                    size="large"
                    onClick={handleExpandClick}
                    disableRipple>
                    <IconButton
                      disableRipple>
                      <ExpandMoreIcon
                        className={` ${clsx(classes.expand, {
                          [classes.expandOpen]: expanded,
                        })} `}
                        aria-expanded={expanded}
                        aria-label="show more"
                        elevation={0}
                        disableRipple
                      />
                    </IconButton>
                  </Fab>
                  {/* <img className="flex rounded resize-img" src="https://scontent.fewr1-1.fna.fbcdn.net/v/t1.0-9/92823856_10157550283822773_1692239302425051136_o.jpg?_nc_cat=108&_nc_sid=85a577&_nc_ohc=uBa9K6okE0IAX9fTEpr&_nc_ht=scontent.fewr1-1.fna&oh=74b587fe3a8df85927d6c6383d3b38a8&oe=5ECE4F01"></img> */}
                  {/* </Fab> */}
                </Grid>
                <Collapse in={expanded}
                  timeout="auto"
                  unmountOnExit>


<Typography component="h6" color="inherit" className={classes.mainFeaturedPostContentTitle} gutterBottom>
                  A New York native and California transplant, I approach life with boldness, sensibility, and open-mindedness to culture.
            </Typography>

            <Typography component="h6" color="inherit" className={classes.mainFeaturedPostContentTitle} gutterBottom>
                  I believe that every generation undergoes a unique paradigm shift that enables us to realize new frontiers in the limits of humanity, and many of my efforts in life are dedicated to conscious initiatives to make better experiences more distributed where they were previously thought to be impossible. 
 
            </Typography>

            <Typography component="h6" color="inherit" className={classes.mainFeaturedPostContentTitle} gutterBottom>
                 
                 I cultivate sophisticated solutions while embracing calculated risk and thoughtful experimentation, and operate with systematic foresight, enduring resilience, and over 9 years of experience across UX Design, Product Management, Growth Strategy, Software Engineering, Video Production/Multimedia, Startups, and SaaS.
                            </Typography>

 

            <Typography component="h6" color="inherit" className={classes.mainFeaturedPostContentTitle} gutterBottom>
            I'm a systematic micro-innovator and problem solver for digital design and technology, spanning but not limited to human-computer interaction design and visionary Minimum Viable Products. As an introspective systems thinker, I design and operate with foresight while elevating clarity and quality.
            </Typography>


            <Typography component="h6" color="inherit" className={classes.mainFeaturedPostContentTitle} gutterBottom>
                  Across my journey, the main constant that remains true to my personality is that I am restlessly driven to solve important problems and design and build for innovative systems and solutions.  
 
            </Typography>






 





                 
                </Collapse>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}


TopCTAAbout1.propTypes = {
  post: PropTypes.object,
  social: PropTypes.array,
};