import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import StefWongResume from './StefWongResume2022.pdf';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  modal: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2, 2, 2),
    margin: theme.spacing(5, 5, 5),
    overflowY: 'auto',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '0px solid #fff',
    // boxShadow: theme.shadows[1],
    padding: theme.spacing(2, 2, 2),
  },
  button: {
    margin: theme.spacing(1, 1, 1, 1),
    // background: blueGrey['A100'],
    background: 'white',
    color: grey[900],
    hover: grey[100],
    textTransform: "none",
    boxShadow: theme.shadows[2],

  }
}));

// let resume = {}

export default function ContactModal() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        size="small"
        disableElevation
        className={classes.button}
        onClick={handleOpen}>
        Résumé
        </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Card disableElevation><center>
            <Button
              className={classes.button}
              color="default" href={StefWongResume} download="Stef-Wong-Resume-2020-Updated.pdf">Download Résumé</Button>
              </center>
            {/* <div className={`${classes.paper}`}>
              <p class="c15 c22 title" id="h.ocvpswguxa6m">
                <h2 class="c18" id="h.ictcjljftz02"></h2>
                <span class="c6 c23">Stef Wong</span>
              </p><h1 class="c3" id="h.9fgvb91io4x1">
                <span class="c13 c19">As a digital mobile and web Product Manager/Product Designer, I empower visionaries to build for success. I turn ideations and constraints into tangible, sophisticated experiences and best-in-class digital products and solutions.</span>
              </h1>
              <p class="c22 c25 subtitle" id="h.aagfqklm9zwt">
                <span class="c16">
                  <a class="c17" href="https://www.google.com/url?q=http://www.stefwong.com&amp;sa=D&amp;ust=1588227006813000"><b>stefwong.com</b></a></span>
                <span>&nbsp; | &nbsp;</span>
                <span class="c16 c20"><a class="c17" href="https://www.google.com/url?q=https://www.linkedin.com/in/stephaniemjwong/&amp;sa=D&amp;ust=1588227006813000"><b>linkedin.com/in/stephaniemjwong</b></a></span>
                <span>&nbsp; | &nbsp;</span><span class="c16"><a class="c17" href="mailto:hifromstef@gmail.com"><b>hifromstef@gmail.com</b></a></span></p>
              <h1 class="c12" id="h.bsa2xhpqbfv2">
                <span class="c9 c6">Skills</span></h1>
              <span class="c7 c6">Product Management, UX Design, Startups, Wireframing, Information Architecture, Growth Strategy, Jira, &nbsp;HTML/CSS/JS, React, Front End Development, Ruby on Rails, Git, SDLC, Adobe Creative Cloud, Product Development, Sketch, Prototyping, Business Analysis, Research, Video Production</span>
              <h1 class="c12" id="h.rlsx4o5b4mpo">
                <span class="c9 c6">Experience</span></h1>
              <span class="c8"><b>General Assembly - Software Engineering Immersive | Engineering Fellow</b></span><p class="c10">
                <span class="c5">FEB</span>
                <span class="c5 c6">&nbsp;2020 - APR 2020</span></p>
              <p class="c1">
                <span class="c7 c6">Built several Minimum Viable Products (MVPs) in React/Ruby on Rails/PostgreSQL and MERN stack. Deployed via Heroku and Netlify, managed in Jira, and wireframed via Adobe XD, LucidChart, and Balsamiq under condensed timeframes via best practices in modern full stack software engineering.</span>
              </p>
              <p class="c1 c4"><span class="c7 c6"></span></p>
              <p class="c1">
                <span class="c8"><b>Constellation (TechStars &lsquo;18) - UX Design | Growth Strategist</b></span>
              </p>
              <p class="c10"><span class="c5">SEPT</span><span class="c5 c6">&nbsp;2018 - NOV 2018</span></p><p class="c1">
                <span class="c7 c6">Sole Developer and Project Lead on the IBM + Columbia University Blockchain Accelerator website. Concurrently led growth strategy for several high-growth startups.</span></p><p class="c1 c4"><span class="c7 c6"></span></p>
              <p class="c1"><span class="c8"><b>NGA Group - Product Manager</b></span></p>
              <p class="c10"><span class="c5">MAY</span><span class="c5 c6">&nbsp;2017 - SEPT 2018</span></p>
              <p class="c1"><span class="c7 c6">Worked directly with the CEO to manage main digital products. Led KPI tracking, quarterly planning, and product strategy. </span></p><p class="c1">
                <span class="c7 c6">Led growth for social mobile/web dating app from 0 to 100,000 users and 2M+ followers across social media accounts, coordinating cross-functionally while managing several workstreams.</span></p>
              <p class="c1 c4">
                <span class="c7 c6"></span></p>
              <p class="c1"><span class="c8"><b>NGA Group - UX Designer</b></span></p><p class="c10"><span class="c5">FEB</span><span class="c5 c6">&nbsp;2017 - SEPT 2018</span></p><p class="c1">
                <span class="c7 c6">UX Designer on flagship mobile and web products.</span></p>
              <p class="c1 c4"><span class="c7 c6"></span></p><p class="c1">
                <span class="c8"><b>Vive Lifestyle (Y-Combinator &lsquo;15) - Product Designer</b></span></p><p class="c10"><span class="c5">JUN</span><span class="c5 c6">&nbsp;2016 - JAN 2017</span></p><p class="c1"><span class="c13">Led end-to-end product design as the sole designer.</span></p><h1 class="c12" id="h.dlaoxi3ta2x2"><span class="c6 c9">Education</span></h1>
              <h3 class="c15" >
                <span class="c6 c7"><b>Rutgers Business School - New Brunswick &nbsp;- Bachelor of Science</b></span></h3>
              <p class="c1"><span class="c5 c6">Withdrew in good academic standing to accept a full time position as a UX Designer.</span></p>
              <h3 class="c15" >
                <span class="c7 c6"><b>General Assembly - Product Management Certificate</b></span>
              </h3>
              <h3 class="c15">
                <span class="c7 c6"><b>Product School - Product Management Certificate</b></span></h3>
              <div className="space margin-bottom"><p class="c3 c4 c22"><span class="c6 c24"></span></p></div>
            </div> */}
            <br></br>
          </Card>
        </Fade>
      </Modal>
    </div>
  );
}
