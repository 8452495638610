import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';




const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'rgba(0,0,0,0.9)',
    },
    sidebarAboutBox: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(3.5),
    },
    toolbar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        display: 'flex',
    },
    toolbarTitle: {
        flex: 1,
    },
    toolbarSecondary: {

        overflowX: 'auto',
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(3.5),
        margin: theme.spacing(3.5),
    },
    toolbarLink: {
        flexShrink: 0,
        color: 'black',
        margin: theme.spacing(1.5),
    },
    button: {
        color: 'white',
        background: 'black',
    },
    link: {
        color: 'black',
    },
    center: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(3.5),
    }
}));

function MusicPlayer(props) {
    const classes = useStyles();
    return (
        <div 
        className={`${classes.root} ${classes.mainFeaturedPost} filtered`} 
        style={{ 
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "cover",
                backgroundAttachment: "fixed", 
                }}>
                        
            <Grid className={classes.sidebarAboutBox}>
                <Typography className={classes.sidebarAboutBox}>Want to to hear uplifting music? Feel free to listen to this track I've created. </Typography>
                {/* <Typography className={classes.sidebarAboutBox}>The first song is one I've recently created in my spare time while being introspective, while consequent songs were selected based on their sense of serenity. </Typography> */}
                
                <iframe
                    width="100%"
                    height="30%"
                    scrolling="no"
                    frameBorder="no"
                    // allow="autoplay"
                    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1043661274&color=%23a3a3a3&auto_play=true&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=true&show_artwork=false&buying=false&sharing=false">
                </iframe>
                <div
                >
                    <a
                        href="https://soundcloud.com/litkitten"
                        title="Lit Kitten"
                        target="_blank"
                    >
                    </a>
                    <a href="https://soundcloud.com/litkitten/sets/clarity"
                        title="Clarity"
                        target="_blank" >
                    </a>
                </div>
            </Grid>
        </div>
    );
}

export default MusicPlayer;