import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Fab, IconButton } from '@material-ui/core';
import { CardMedia } from '@material-ui/core';
import LinkedInIcon from '@material-ui/icons/LinkedIn';



const useStyles = makeStyles((theme) => ({
  mainFeaturedPost: {
    position: 'relative',
    // backgroundColor: theme.palette.grey[800],
    backgroundColor: 'black',
    color: theme.palette.common.white,
    // borderRadius: 4,
    // marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    // display: 'flex',
    // justifyContent: 'center',
    // flexWrap: 'wrap',
    // listStyle: 'none',
    padding: theme.spacing(3.5),
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,1.8)',
    borderRadius: 4,
  },
  mainFeaturedPostContent: {
    position: 'relative',
    // padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
    },
  },
  mainFeaturedPostContentTitle: {
    position: 'relative',
    lineHeight: theme.spacing(.16),
  },

  fab: {
    backgroundColor: 'white',
    color: 'grey',
    // button: {
    //   textTransform: "none"
    // },
  },
  fabWrapper: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(3),
    display: 'flex',
    // justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
  },
  mainWrapper: {
    margin: theme.spacing(3.5),
    // paddingTop: theme.spacing(3),
    direction: 'column',
    // justifyContent: 'center',
    
    // flexWrap: 'wrap',
    // listStyle: 'none',
  },
  link: {
    color: 'white',
  }
}));



export default function CTA(props) {
  const classes = useStyles();
  // const { post } = props;


  return (
    <>
      <CardMedia 
    //   component="video"
      className={classes.mainFeaturedPost} 
    >
      <Paper elevation={0} className={classes.mainFeaturedPost}>
        {/* Increase the priority of the hero background image */}
        {/* {<CardMedia component="video" style={{ display: 'none' }} src={post.image} alt={post.imageText} />} */}

        <div className={classes.overlay} />
        <Grid container>
          <Grid item sm={12} md={12}>
            <div className={classes.mainFeaturedPostContent}>
              <Typography component="h1" variant="h2"  className={classes.mainFeaturedPostContentTitle} gutterBottom>
Connect
              </Typography>

              </div>

              
      
      
            <div className={classes.mainFeaturedPostContent}>
            <Typography component="h6"  color="inherit" className={classes.mainFeaturedPostContentTitle} gutterBottom>
             To get in touch, you're welcome to message me on LinkedIn or  <a className={classes.link} href="mailto:hifromstef@gmail.com">
               email me.</a> 
              </Typography>

              </div>
            
   
              <div className={classes.mainFeaturedPostContent}>
<div className={classes.fabWrapper}>

<a href="https://www.linkedin.com/in/stephaniemjwong/" target="_blank" className="ignoreUnderline">
                <div className="space-fab">
                  <Fab variant="extended" size="large" className={classes.fab}>
                    <IconButton disableRipple disableFocusRipple disableTouchRipple disabled> <LinkedInIcon /></IconButton>

                    {/* <img className="flex rounded resize-img" src="https://scontent.fewr1-1.fna.fbcdn.net/v/t1.0-9/92823856_10157550283822773_1692239302425051136_o.jpg?_nc_cat=108&_nc_sid=85a577&_nc_ohc=uBa9K6okE0IAX9fTEpr&_nc_ht=scontent.fewr1-1.fna&oh=74b587fe3a8df85927d6c6383d3b38a8&oe=5ECE4F01"></img> */}
                    <span className="space"> Connect</span>
                  </Fab>
                  
          
                </div>
              </a>
              </div>
              </div>
          

    
          
          </Grid>






        </Grid>

      </Paper>
      </CardMedia>

    </>
  );
}

