import React from 'react';
import './App.css';
import Blog from './Blog.js';




function App() {


  return (
    <>

        <Blog>

        </Blog>

    </>
  );
}

export default App;
