import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Fab, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles((theme) => ({
    root: {

        // backgroundImage: `url('https://instagram.fewr1-6.fna.fbcdn.net/v/t51.2885-15/sh0.08/e35/s750x750/10654896_1695862857369555_1268647052_n.jpg?_nc_ht=instagram.fewr1-6.fna.fbcdn.net&_nc_cat=103&_nc_ohc=NXtmx45UewsAX-2Xui1&oh=3112456b7851f5232f51bca89411e036&oe=5EE3CA66')`,
        // backgroundImage: `url('https://images.unsplash.com/photo-1558346648-9757f2fa4474?ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80')`,
        // backgroundImage: `url('https://images.unsplash.com/flagged/photo-1551771304-bf11d626cdf2?ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80')`,
        // backgroundColor: 'rgba(0,0,0,0.8)',
    },
    mainFeaturedPost: {
        position: 'relative',
        // backgroundColor: theme.palette.grey[800],
        // backgroundColor: 'black',
        // color: theme.palette.common.white,
        // borderRadius: 4,
        marginBottom: theme.spacing(2),
        backgroundColor: 'rgba(0,0,0,0.0)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        // display: 'flex',
        // justifyContent: 'center',
        // flexWrap: 'wrap',
        // listStyle: 'none',
        padding: theme.spacing(3.5),
        paddingTop: theme.spacing(12),
        paddingBottom: theme.spacing(12),
    },
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,0.0)',
        // borderRadius: 4,
    },
    mainFeaturedPostContent: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        position: 'relative',
        // padding: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            listStyle: 'none',
        },
    },
    mainFeaturedPostContentTitle: {
        position: 'relative',
        lineHeight: theme.spacing(.16),
    },

    fab: {
        backgroundColor: 'white',
        color: 'grey',
        // button: {
        //   textTransform: "none"
        // },
    },
    fabWrapper: {
        marginTop: theme.spacing(2),
        paddingTop: theme.spacing(3),
        display: 'flex',
        // justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
    },
    mainWrapper: {
        margin: theme.spacing(3.5),
        direction: 'column',
    }
}));



export default function BrandStatement2(props) {
    const classes = useStyles();

    return (
        <div
            className={`${classes.root} ${classes.mainFeaturedPost} filtered`}
            style={{
                // backgroundImage: `url(${post.image})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "cover",
                backgroundAttachment: "fixed",
                //         perspective: "1px",
                // transform: "translateZ(-1px)",
                // transform: "scale(1.1)",
            }}>
            <Paper elevation={0} className={classes.mainFeaturedPost}>

                {/* {<CardMedia component="video" style={{ display: 'none' }} src={post.image} alt={post.imageText} />} */}

                <div className={classes.overlay} />
                <Grid container>
                    <Grid item sm={12} md={12}>
                        <div className={classes.mainFeaturedPostContent}>
                            <Typography component="h2" variant="h2" color="inherit" className={classes.mainFeaturedPostContentTitle} gutterBottom>
                                Portfolio
              </Typography>
                        </div>
                        <div className={classes.mainFeaturedPostContent}>
                            <Typography component="h6" color="inherit" className={classes.mainFeaturedPostContentTitle} gutterBottom>
                                A select few of my works
              </Typography>
                        </div>
                        <div className={classes.mainFeaturedPostContent}>
                            <div className={classes.fabWrapper}>
                                <a href="#portfolio" className="ignoreUnderline">
                                    <Fab variant="extended" size="large" className={classes.fab}>
                                        <span className="space-alt"> view portfolio</span>
                                        <IconButton disableRipple disabled> <ExpandMoreIcon /></IconButton>
                                    </Fab>
                                </a>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}
