import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import ContactModal from './ContactModal';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    // borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0.5),
    flexShrink: 0,
      backgroundColor: 'rgba(0,0,0,0.0)',
    // overflowX: 'auto',
    // justifyContent: 'center',
    // flexWrap: 'wrap',
    listStyle: 'none',
    // padding: theme.spacing(3.5),
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
    // display: 'flex',
    // justifyContent: 'center',
    // flexWrap: 'wrap',
    // listStyle: 'none',
    padding: theme.spacing(3.5),
  },
  toolbarLink: {
    padding: theme.spacing(0.5),
    flexShrink: 0,
    textDecoration: "none",
    color: "black",
  },
  button: {
    textTransform: "none"
  },
}));

export default function Header(props) {
  const classes = useStyles();
  const { sections } = props;

  return (
    <div id="#stef">
      <Toolbar className={classes.toolbar}>

    
      
          {/* <div className="border-logo"> */}
            {/* STEF */}
            {/* </div> */}
  
          {sections.map((section) => (
            <Grid>
              <a
                color="inherit"
                // key={section.title}
                variant="body2"
                href={section.url}
                className={classes.toolbarLink}
              >
                {section.title}
              </a>
            </Grid>
          ))}
          <ContactModal />
 
      </Toolbar>
    </div>
  );
}

Header.propTypes = {
  sections: PropTypes.array,
};