import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ExpandableCard from './ExpandableCard';


const useStyles = makeStyles((theme) => ({
    card: {
        // display: 'flex',
        [theme.breakpoints.down('sm')]: {

        },
    },
    cardDetails: {
        // flex: 1,
    },
}));

export default function FeaturedPost(props) {
    const classes = useStyles();
    const { post } = props;

    return (
        <Grid item className={classes.card} xs={12} sm={12} md={6}>
 
                <ExpandableCard 
                post={post}
                >

                </ExpandableCard>
        
        </Grid>

    );
}

FeaturedPost.propTypes = {
    post: PropTypes.object,
};
