import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button'
import ReactPlayer from 'react-player';
import { grey, blueGrey, red } from '@material-ui/core/colors';
import Chip from '@material-ui/core/Chip';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(3.5),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
    },
    media: {
        height: 0,
        // width: '100%',
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            listStyle: 'none',
        },
        // maxHeight: 500,
        // maxHeight: 720,
        // paddingTop: '56.25%', // 16:9
    },
    mediaMobile: {
        height: 0,
        maxWidth: 320,
        // maxWidth: 400,
        // width: '100%',
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),

            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            listStyle: 'none',
        },
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        marginRight: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        background: grey[100],
    },
    button: {
        // margin: theme.spacing(2.5),
        background: blueGrey['50'],
        color: grey[900],
        // color: 'white',
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red['A400'],
    },
    marginAutoItem: {
        // margin: 'auto'
    },
    alignItemsAndJustifyContent: {

        backgroundColor: 'pink',
    },
    chip: {
        margin: theme.spacing(0.5),
        background: grey[900],
        color: 'white',
    },
    chipSpace: {
        marginRight: theme.spacing(12),
        marginLeft: theme.spacing(12),
    }
}));

export default function ExpandableCard(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = (event) => {
        event.preventDefault()
        setExpanded(!expanded);

    };

    // const handleContentClick = (event) => {
    //     event.preventDefault()
    // }
    // console.log(props.post.tags)

    return (

        <Card elevation={0}>
            <Grid item className={classes.root} item xs={12} sm={12} md={12}>
                <Typography variant="h6">
                    {props.post.description}
                </Typography>
            </Grid>
  
            <div className="player-wrapper">
                <Grid item xs={12} sm={6} md={6}>
                    {<ReactPlayer
                        className={`${classes.media} react-player`}
                        onContextMenu={e => e.preventDefault()}
                        width="100%"
                        height="100%"
                        url={props.post.video}
                        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                        controls
                    >
                    </ReactPlayer>
                        ?
                        <ReactPlayer
                            className={`${classes.media} react-player`}
                            onContextMenu={e => e.preventDefault()}
                            width="100%"
                            height="100%"
                            url={props.post.video}
                            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                            controls
                        >
                        </ReactPlayer>
                        :
                        null
                    }
                </Grid>
            </div>

            {/* 
            <CardContent onClick={handleContentClick}>

            </CardContent> */}


            <Grid className={`${classes.root} ${classes.chipSpace}`}>
                {props.post.tag1
                    ?
                    <Chip
                        label={props.post.tag1}
                        className={classes.chip}
                    />
                    :
                    null
                }
                {props.post.tag2
                    ?
                    <Chip
                        label={props.post.tag2}
                        className={classes.chip}
                    />
                    :
                    null
                }
                {props.post.tag3
                    ?
                    <Chip
                        label={props.post.tag3}
                        className={classes.chip}
                    />
                    :
                    null
                }
                {props.post.tag4
                    ?
                    <Chip
                        label={props.post.tag4}
                        className={classes.chip}
                    />
                    :
                    null
                }
                {props.post.tag5
                    ?
                    <Chip
                        label={props.post.tag5}
                        className={classes.chip}
                    />
                    :
                    null
                }
                {props.post.tag6
                    ?
                    <Chip
                        label={props.post.tag6}
                        className={classes.chip}
                    />
                    :
                    null
                }
                {props.post.tag7
                    ?
                    <Chip
                        label={props.post.tag7}
                        className={classes.chip}
                    />
                    :
                    null
                }
                {props.post.tag8
                    ?
                    <Chip
                        label={props.post.tag8}
                        className={classes.chip}
                    />
                    :
                    null
                }
                {props.post.tag9
                    ?
                    <Chip
                        label={props.post.tag9}
                        className={classes.chip}
                    />
                    :
                    null
                }
            </Grid>


            <CardActions className={classes.root}>
                <a href={props.post.externalUrl} target="_blank" className={`${classes.root} ignoreUnderline`}>
                    {props.post.title
                        ?
                        <Grid item xs={12} sm={12} md={12} className={classes.root}>
                            <Button className={classes.button} size="large">
                                {props.post.title}

                            </Button>
                        </Grid>
                        :
                        null
                    }
                </a>
            </CardActions>

            <CardActions>
                <Grid item xs={12} sm={12} md={12}>

                    <Grid className={classes.root}>

                        <span className={classes.root}>
                            <span className='y-center padding'>
                                Learn more
                            </span>
                        </span>

                        <Fab>
                            <IconButton
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: expanded,
                                })}
                                onClick={handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </Fab>


                    </Grid>



                </Grid>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>

                    <Typography variant="subtitle1" paragraph>
                        {props.post.headerText
                        ?
                        props.post.headerText
                        :
                        null}
                        {props.post.paragraph1
                        ?
                        props.post.paragraph1
                        :
                        null}
                        {props.post.paragraph2
                        ?
                        props.post.paragraph2
                        :
                        null}
                        {props.post.paragraph3
                        ?
                        props.post.paragraph3
                        :
                        null}
                    </Typography>


                </CardContent>
            </Collapse>
        </Card>
    );
}

