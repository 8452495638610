import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { CardMedia } from '@material-ui/core';
import Pillar1 from './images/GrowingIvy1.png';
import Pillar2 from './images/GrowingIvy2.png';
import Pegasus1 from './images/Pegasus1.png';
import Pegasus2 from './images/Pegasus2.png';
import Pegasus3 from './images/Pegasus3.png';
import GrowingIvy1 from './images/GrowingIvy1.png';
import Container from '@material-ui/core/Container';



const useStyles = makeStyles((theme) => ({
  mainFeaturedPost: {
    position: 'relative',
    // backgroundColor: 'black',
    // color: theme.palette.common.white,
    // background: 'linear-gradient(to bottom, rgba(207,66,100,0.35) 0%, rgba(25,10,180,.35) 50%, rgba(255,237,188,.35) 100%)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    padding: theme.spacing(3.5),
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(16),
    backgroundColor: 'rgba(0,0,0,0.0)',

  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    // backgroundColor: 'rgba(255,255,255,0.0)',
    // backgroundColor: 'rgba(255,255,255,0.97)',
    // width: '80%',
    // filter: 'blur(8em)',
  },
  mainFeaturedPostContent: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
    },
  },
  mainFeaturedPostContentTitle: {
    paddingRight: theme.spacing(1),
    position: 'relative',
    lineHeight: theme.spacing(.16),
  },

  fab: {
    backgroundColor: 'white',
    color: 'grey',
  },
  fabWrapper: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
  },
  mainWrapper: {
    margin: theme.spacing(3.5),
    direction: 'column',
  }
}));



export default function BrandStatement(props) {
  const classes = useStyles();


  return (
    <div id="about">
      <Container className={classes.mobile} maxWidth="lg">
        <CardMedia
          className={classes.mainFeaturedPost}
        >
          <Paper elevation={0} className={classes.mainFeaturedPost}>


            <div className={classes.overlay} />
            <Grid container>
              <Grid item sm={12} md={12}>
                <div className={classes.mainFeaturedPostContent}>

                  <Typography component="h3" variant="h3" className={classes.mainFeaturedPostContentTitle} gutterBottom>
                    Vision.

                    Precision.

                    Tenacity.
              </Typography>

                </div>

                <div className={classes.mainFeaturedPostContent}>
                  <Typography variant="h6" variant="h6" color="inherit" className={`${classes.mainFeaturedPostContentTitle} `}>
                    I empower visionaries to build for success.
              </Typography>

                  <div className={classes.mainFeaturedPostContent}>
                    <div className={classes.mainFeaturedPostContentTitle}>
                      <div className='flex filtered w-full-screen flex justify-center'>
                      
                        {/* <img src={Pillar1} className="scale-down-width ">
                        </img> */}
                
                        <img 
                        // src={Pegasus2} 
                        // src={Pegasus3} 
                        // src={GrowingIvy1} 
                        className="scale-down-width filtered">
                        </img>
                   
                        {/* <img src={Pillar2} className="scale-down-width ">
                        </img> */}
                      </div>
                    </div>
                  </div>


                </div>



              </Grid>

            </Grid>

          </Paper>
        </CardMedia>

      </Container>
    </div>
  );
}


