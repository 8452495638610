import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import flowstacksvid from './flowstacksvid.mp4'
import profilePic from './StefWongProfilePicture2020.jpg'
import { teal } from '@material-ui/core/colors';



const useStyles = makeStyles((theme) => ({
  mainFeaturedPost: {
    position: 'relative',
    // color: grey['900'],
    background: 'linear-gradient(to bottom, rgba(207,66,100,0.35) 0%, rgba(25,10,180,.35) 50%, rgba(255,237,188,.35) 100%)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    paddingTop: theme.spacing(36),
    paddingBottom: theme.spacing(36),
    padding: theme.spacing(3.5),
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    // paddingTop: theme.spacing(9),
    // paddingBottom: theme.spacing(16),
    [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
    },
    // padding: theme.spacing(3.5),
},
overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.2)',
    // width: '80%',
    // filter: 'blur(8em)',
},

  mainFeaturedPostContent: {
    position: 'relative',

    [theme.breakpoints.up('xs')]: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
    },
  },
  mainFeaturedPostContentTitle: {
    position: 'relative',
    lineHeight: theme.spacing(.16),
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },

  fab: {
    backgroundColor: 'white',
    color: 'grey',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  inline: {
    display: 'inline',
  },
  color: {
    color: teal['A200'],
  },
  spacer: {
    marginLeft: theme.spacing(3.5),
    paddingRight: theme.spacing(3.5),
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
  },
  border: {
    border: 2,
  },
  borderWelcome: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(-6),

  }
}));

export default function MainFeaturedPost(props) {
  const classes = useStyles();
  const { post } = props;

  return (
    <>
      <div className={`${classes.mainFeaturedPost} filtered`}
        style={{
          backgroundImage: `url(${post.image4})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          // backgroundColor: "rgba(255, 255, 255, 0.95)",
          // transform: "translateZ(-1px)",
          // transform: "scale(1.0)",
          // height: "40vh",
        }}>
        <div className={classes.overlay} />
        <Typography className={`${classes.mainFeaturedPostContentTitle} ${classes.borderWelcome} border-welcome`} gutterBottom>
                &nbsp;WELCOME&nbsp;
              </Typography>
        <Grid container className={classes.mainFeaturedPostContent}>
          <Grid item sm={12} md={12}>
            <div className={classes.mainFeaturedPostContent}>
              <Typography color="inherit">
                <center>
                <Grid className={`${classes.mainFeaturedPostContent} vw-resize` }>
                  <img className="profile-pic filtered margin-bottom" src={profilePic}></img>
                </Grid>
                </center>
                <center>
                <div classes="phrase-wrapper">
                  <div classes={classes.container}>
                    <div classes={classes.inline}>
                      {/* <center className={classes.inline}> */}
                        <span className="phrase-start">  <p></p>I'm <b>Stef</b>, a UX/Product Design strategist and technologist.</span>
                        {/* <div className="fade words">
                          <span className={classes.color}>&nbsp;human</span>
                          <span className={classes.inline}>&nbsp;NY native</span>
                          <span className={classes.inline}>&nbsp;strategist</span>
                          <span className={classes.inline}>&nbsp;designer</span>
                          <span className={classes.inline}>&nbsp;developer</span>
                          <span className={classes.inline}>&nbsp;coder</span>
                          <span className={classes.inline}>&nbsp;technologist</span>
                          <span className={classes.inline}>&nbsp;woman</span>
                          <span className={classes.inline}>&nbsp;tactician</span>
                          <span className={classes.inline}>&nbsp;futurist</span>
                        </div> */}
                      {/* </center>                */}
                    </div>
                  </div>
                </div>     
                </center>       
              </Typography>
            </div>
            <Grid className={classes.mainFeaturedPostContent}>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}


MainFeaturedPost.propTypes = {
  post: PropTypes.object,
};